<template>
  <div id="page-user-view">
    <big-title>Mi <span>perfil</span>.
      <vs-alert v-if="SupplierStatus < 3" icon-pack="feather" icon="icon-alert-triangle" class="h-full mt-4" color="danger">
        <span class="font-regular">Tu perfil de instalador está <strong>pendiente</strong> de ser completado. 
        Puedes ir al apartado "Actualizar" para llenar los datos faltantes.</span>
      </vs-alert>
      <vs-alert v-if="SupplierStatus == 3" icon-pack="feather" icon="icon-clock" class="h-full mt-4" color="warning">
        <span class="font-regular">Tu perfil se encuentra <strong>en proceso de revisión</strong>.
        En breve quedará verificado.</span>
      </vs-alert>
      <vs-alert v-if="SupplierStatus > 3" icon-pack="feather" icon="icon-check" class="h-full mt-4" color="success">
        <span class="font-regular">Tu perfil se encuentra <strong>verificado</strong>.</span>
      </vs-alert>
    </big-title>

    <div id="user-data" v-if="mounted">

      <vx-card title="Datos de Empresa" class="mb-base">

        <!-- Avatar -->
        <div class="vx-row">

          <!-- Avatar Col -->
          <div class="vx-col" id="avatar-col">
            <div class="img-container mb-4">
              <img :src="avatar" class="rounded w-full" alt="logo" />
            </div>
          </div>

          <!-- Information - Col 1 -->
          <div class="vx-col flex-1" id="account-info-col-1">
            <table aria-describedby="general">
              <th scope="col"></th>
              <tr  v-for="(f, index) in mainSecR1" :key="index">
                <td class="font-semibold">{{f.label}}</td>
                <td>{{ fieldValue(f.field) }}</td>
              </tr>              
            </table>
          </div>
          <!-- /Information - Col 1 -->

          <!-- Information - Col 2 -->
          <div class="vx-col flex-1" id="account-info-col-2" >
            <table aria-describedby="general2">
              <th scope="col"></th>
              <tr v-for="(f, index) in mainSecR2" :key="index">
                <td class="font-semibold">{{f.label}}</td>
                <td>{{ fieldValue(f.field) }}</td>
              </tr>              
            </table>
          </div>
          <!-- /Information - Col 2 -->

          <div class="vx-col w-full flex mt-3" id="account-manage-buttons">
            <vs-button icon-pack="feather" icon="icon-edit" class="mr-4" :to="{name: 'perfil-editar'}">Actualizar</vs-button>
          </div>
        </div>
      </vx-card>

      <vx-card title="Más Datos" class="mb-base">
        <p>Da click en las secciones para desplegar todos los datos.</p>

        <vs-collapse type="margin" accordion >
          
          <!--vs-collapse-item>
            <div slot="header">Acreditaciones</div>
            <div class="vx-row">           
              <div class="vx-col lg:w-1/2 w-full">
                <table aria-describedby="acreditaciones">
                  <th scope="col"></th>
                  <tr v-for="(f, index) in fieldsLoaded.filter(x => x.section == 'acreditations')" :key="index">
                    <td class="font-semibold pr-3">{{f.label}}</td>
                    <td>{{ fieldValue(f.field, f.format) }}</td>
                  </tr>
                </table>
              </div>
              <div class="vx-col lg:w-1/2 w-full">
                <table aria-describedby="references">
                  <th scope="col"></th>
                  <tr v-for="(f, index) in fieldsLoaded.filter(x => x.section == 'references')" :key="index">
                    <td class="font-semibold pr-3">{{f.label}}</td>
                    <td>{{ fieldValue(f.field, f.format) }}</td>
                  </tr>              
                </table>
              </div> 
            </div>
          </vs-collapse-item-->

          <vs-collapse-item>
            <div slot="header">{{contactsCardTitle}}</div>
            <div class="vx-row">
              <div class="vx-col lg:w-1/3 md:w-1/2 w-full">
                  <table aria-describedby="keyContacts1">
                    <th scope="col"></th>
                    <tr v-for="(f, index) in fieldsLoaded.filter(x => x.section == 'keyContacts1')" :key="index">
                      <td class="font-semibold pr-3">{{f.label}}</td>
                      <td>{{ fieldValue(f.field, f.format) }}</td>
                    </tr>              
                  </table>          
              </div> 
              <div class="vx-col lg:w-1/3 md:w-1/2 w-full">
                  <table aria-describedby="keyContacts2">
                    <th scope="col"></th>
                    <tr v-for="(f, index) in fieldsLoaded.filter(x => x.section == 'keyContacts2')" :key="index">
                      <td class="font-semibold pr-3">{{f.label}}</td>
                      <td>{{ fieldValue(f.field, f.format) }}</td>
                    </tr>              
                  </table>
              </div> 
              <div class="vx-col lg:w-1/3 md:w-1/2 w-full">
                  <table aria-describedby="keyContacts3">
                    <th scope="col"></th>
                    <tr v-for="(f, index) in fieldsLoaded.filter(x => x.section == 'keyContacts3')" :key="index">
                      <td class="font-semibold pr-3">{{f.label}}</td>
                      <td>{{ fieldValue(f.field, f.format) }}</td>
                    </tr>              
                  </table>
              </div> 
            </div>
          </vs-collapse-item>

          <vs-collapse-item>
            <div slot="header">Contactos Clave</div>

            <div class="vx-row">
              <div class="vx-col xl:w-1/3 lg:w-1/2 md:w-2/3 sm:w-full w-full">
                <vx-card title="Director General" class="mb-2">
                  <table aria-describedby="keyContacts.general">
                    <th scope="col"></th>
                    <tr v-for="(f, index) in fieldsLoaded.filter(x => x.section == 'keyContacts.general')" :key="index">
                      <td class="font-semibold">{{f.label}}</td>
                      <td>{{ fieldValue(f.field) }}</td>
                    </tr>              
                  </table>
                </vx-card>
              </div>
              <div class="vx-col xl:w-1/3 lg:w-1/2 md:w-2/3 sm:w-full w-full">
                <vx-card title="Director Comercial" class="mb-2">
                  <table aria-describedby="keyContacts.commerce">
                    <th scope="col"></th>
                    <tr v-for="(f, index) in fieldsLoaded.filter(x => x.section == 'keyContacts.commerce')" :key="index">
                      <td class="font-semibold">{{f.label}}</td>
                      <td>{{ fieldValue(f.field) }}</td>
                    </tr>              
                  </table>
                </vx-card>
              </div>
              <div class="vx-col xl:w-1/3 lg:w-1/2 md:w-2/3 sm:w-full w-full">
                <vx-card title="Director de Operaciones" class="mb-2">
                  <table aria-describedby="keyContacts.operations">
                    <th scope="col"></th>
                    <tr v-for="(f, index) in fieldsLoaded.filter(x => x.section == 'keyContacts.operations')" :key="index">
                      <td class="font-semibold">{{f.label}}</td>
                      <td>{{ fieldValue(f.field) }}</td>
                    </tr>              
                  </table>
                </vx-card>
              </div>       
            </div>
          </vs-collapse-item>

          <vs-collapse-item>
            <div slot="header">Domicilio</div>
            <div class="vx-row">           
              <div class="vx-col lg:w-1/2 w-full">
                  <table aria-describedby="location1">
                    <th scope="col"></th>
                    <tr v-for="(f, index) in fieldsLoaded.filter(x => x.section == 'location1')" :key="index">
                      <td class="font-semibold pr-3">{{f.label}}</td>
                      <td>{{ fieldValue(f.field, f.format) }}</td>
                    </tr>              
                  </table>
              </div> 
              <div class="vx-col lg:w-1/2 w-full">
                  <table aria-describedby="location2">
                    <th scope="col"></th>
                    <tr v-for="(f, index) in fieldsLoaded.filter(x => x.section == 'location2')" :key="index">
                      <td class="font-semibold pr-3">{{f.label}}</td>
                      <td>{{ fieldValue(f.field, f.format) }}</td>
                    </tr>
                  </table>
              </div>
            </div>
          </vs-collapse-item>

          <vs-collapse-item>
            <div slot="header">Datos Bancarios</div>
            <div class="vx-row">           
              <div class="vx-col lg:w-1/2 w-full">
                  <table aria-describedby="bank">
                    <th scope="col"></th>
                    <tr v-for="(f, index) in fieldsLoaded.filter(x => x.section == 'bank')" :key="index">
                      <td class="font-semibold pr-3">{{f.label}}</td>
                      <td>{{ fieldValue(f.field, f.format) }}</td>
                    </tr>              
                  </table>
              </div> 
            </div>
          </vs-collapse-item>

        </vs-collapse>
      </vx-card>
    </div>
    
    <load-error v-if="failed" />
  </div>
</template>

<script>

const userTypeDefs = [
  { id: 0, label: "Persona Moral Mexicana" },
  { id: 1, label: "Persona Física con residencia temporal o permanente en México" },
  { id: 2, label: "Persona Física sin residencia temporal o permanente en México (Extranjero)" },
  { id: 3, label: "Personal Moral Extranjera" },
  { id: 4, label: "Sociedad, dependencia o entidad (Entidad financiera)" },
];

const fieldsDefinitions = [
  { id: 1, label: "Nombre comercial", field: "company.tradename", section: "main", for: "all" },
  { id: 2, label: "Razón social", field: "company.rs", section: "main", for: "0" },
  { id: 0, label: "Correo comercial", field: "company.email", section: "main", for: "all"},
  { id: 3, label: "RFC", field: "company.rfc", section: "main", for: "0" },
  { id: 4, label: "Página web", field: "company.webpage", section: "main", for: "all" },
  //{ id: 4, label: "Descripción", field: "company.extra_info", section: "main", for: "all" },

  { id: 5, label: "Facebook", field: "company.facebook", section: "main", for: "all" },
  { id: 6, label: "Youtube", field: "company.youtube", section: "main", for: "all" },
  { id: 7, label: "Twitter", field: "company.twitter", section: "main", for: "all" },
  { id: 8, label: "LinkedIn", field: "company.linkedin", section: "main", for: "all" },

  { id: 6, label: "Acreditación/Certificado 1: ", field: "acreditations.acredit_1_file", section: "acreditations", for: "all", format: 'loaded' },
  { id: 7, label: "Acreditación/Certificado 2: ", field: "acreditations.acredit_2_file", section: "acreditations", for: "all", format: 'loaded' },
  { id: 8, label: "Acreditación/Certificado 3: ", field: "acreditations.acredit_3_file", section: "acreditations", for: "all", format: 'loaded' },
  { id: 5, label: "Presentación ejecutiva: ", field: "acreditations.cv_file", section: "acreditations", for: "all", format: 'loaded' },

  { id: 6, label: "Nombre testimonio 1", field: "acreditations.testimony_name_1", section: "references", for: "all", format: '' },
  { id: 7, label: "Teléfono testimonio 1", field: "acreditations.contact_phone_1", section: "references", for: "all", format: '' },
  { id: 6, label: "Nombre testimonio 2", field: "acreditations.testimony_name_2", section: "references", for: "all", format: '' },
  { id: 7, label: "Teléfono testimonio 2", field: "acreditations.contact_phone_2", section: "references", for: "all", format: '' },
  { id: 6, label: "Nombre testimonio 3", field: "acreditations.testimony_name_3", section: "references", for: "all", format: '' },
  { id: 7, label: "Teléfono testimonio 3", field: "acreditations.contact_phone_3", section: "references", for: "all", format: '' },

  { id: 7, label: "Nombre", field: "contacts.first_name", section: "keyContacts1", for: "all", format: '' },
  { id: 7, label: "Segundo nombre", field: "contacts.second_name", section: "keyContacts1", for: "all", format: '' },
  { id: 7, label: "Primer apellido", field: "contacts.last_name_1", section: "keyContacts1", for: "all", format: '' },
  { id: 7, label: "Segundo apellido", field: "contacts.last_name_2", section: "keyContacts1", for: "all", format: '' },
  { id: 7, label: "País de Nacionalidad", field: "contacts.citizenship", section: "keyContacts2", for: "all", format: '' },
  { id: 7, label: "País de Residencia", field: "contacts.residence", section: "keyContacts2", for: "all", format: '' },
  //{ id: 7, label: "ID Oficial - Frente: ", field: "contacts.id", section: "keyContacts2", for: "all", format: 'loaded' },
  //{ id: 7, label: "ID Oficial - Posterior: ", field: "contacts.idBack", section: "keyContacts2", for: "all", format: 'loaded' },
  { id: 7, label: "RFC", field: "contacts.rfc", section: "keyContacts3", for: "all", format: '' },
  { id: 7, label: "CURP", field: "contacts.curp", section: "keyContacts3", for: "all", format: '' },
  { id: 7, label: "Teléfono", field: "contacts.phone", section: "keyContacts3", for: "all", format: '' },  

  { id: 7, label: "Nombre completo", field: "contacts.general.name", section: "keyContacts.general", for: "all", format: '' },
  { id: 7, label: "Teléfono", field: "contacts.general.phone", section: "keyContacts.general", for: "all", format: '' },
  { id: 7, label: "Correo", field: "contacts.general.email", section: "keyContacts.general", for: "all", format: '' },

  { id: 7, label: "Nombre completo", field: "contacts.commerce.name", section: "keyContacts.commerce", for: "all", format: '' },
  { id: 7, label: "Teléfono", field: "contacts.commerce.phone", section: "keyContacts.commerce", for: "all", format: '' },
  { id: 7, label: "Correo", field: "contacts.commerce.email", section: "keyContacts.commerce", for: "all", format: '' },

  { id: 7, label: "Nombre completo", field: "contacts.operations.name", section: "keyContacts.operations", for: "all", format: '' },
  { id: 7, label: "Teléfono", field: "contacts.operations.phone", section: "keyContacts.operations", for: "all", format: '' },
  { id: 7, label: "Correo", field: "contacts.operations.email", section: "keyContacts.operations", for: "all", format: '' },

  { id: 7, label: "Calle", field: "location.street", section: "location1", for: "all", format: '' },
  { id: 7, label: "No. Exterior", field: "location.ext_number", section: "location1", for: "all", format: '' },
  { id: 7, label: "No. Interior", field: "location.int_number", section: "location1", for: "all", format: '' },
  { id: 7, label: "Colonia", field: "location.neighborhood", section: "location1", for: "all", format: '' },
  { id: 7, label: "Estado", field: "location.state", section: "location1", for: "all", format: '' },
  { id: 7, label: "Ciudad o población", field: "location.city", section: "location2", for: "all", format: '' },
  { id: 7, label: "Municipio", field: "location.municipality", section: "location2", for: "all", format: '' },
  { id: 7, label: "Código postal", field: "location.zipcode", section: "location2", for: "all", format: '' },
  { id: 7, label: "Teléfono", field: "location.phone", section: "location2", for: "all", format: '' },

  { id: 7, label: "CLABE", field: "bank.clabe", section: "bank", for: "all", format: '' },
  //{ id: 7, label: "Encabezado Estado de Cuenta:", field: "bank.account", section: "bank", for: "all", format: 'loaded' },
];

import _h_perfil from '@assets/images/perfil/h_perfil.png'
import _m_perfil from '@assets/images/perfil/m_perfil.png'

export default {
  data () {
    return {
      user_data: null,
      user_not_found: false,
      logo: null,
      mounted: false,
      loading: false,
      failed: false,
      fieldsLoaded: [],
      supplier: {},
      userType: null,
      mainSecR1: [],
      mainSecR2: [],
    }
  },
  computed: {
    userAddress () {
      let str = ''
      for (const field in this.user_data.location) {
        str += `${field  } `
      }
      return str
    },
    avatar(){
      return _h_perfil;
      if(this.logo != null && this.logo != ''){
        return this.logo;
      }
      else {
        return '/images/perfil/h_perfil.png'
      }
    },
    contactsCardTitle(){
      return this.userType == 0 ? "Apoderado Legal" : "Datos de Contacto";
    },
    isVerifiedOrProcess(){
      return this.SupplierStatus >= 3;
    },
  },
  methods: {
    getInfo(){
       let g = this;
      this.loading = true;
      this.mounted = false;
      axios.get("/api/v1/supplier/getFullProfile").then(response => {
        g.supplier = response.data;
        g.logo = response.data.logo_file;
        g.userType = response.data.base.type;        
        this.loadFields();
      }).catch(e =>{
        this.loading = false;
        this.failed = true;
      });
    },
    loadFields(){
      fieldsDefinitions.forEach(e => {
        if(e.for == "all" || e.for.includes(this.userType)){
          this.fieldsLoaded.push(e);
        }
      });
      let mainSection = this.fieldsLoaded.filter(x => x.section == "main");
      let total = mainSection.length;
      let r1 = Math.ceil(total / 2);
      let c = 1;
      mainSection.forEach(e => {
        if(c <= r1){
          this.mainSecR1.push(e);
        }
        else {
          this.mainSecR2.push(e);
        }
        c += 1;
      });
      this.loading = false;
      this.mounted = true;
    },
    fieldValue(field, format = null){
      let val = this.getNestedObject(this.supplier, this.arrayFromFieldName(field) );
      if(format != null && format != ''){
        return this.formatConvert(val, format);
      }
      else {
        return val;
      }
    },
    formatConvert(val, format){
      if(format == "loaded"){
        return (val == null || val == '') ? "Pendiente" : "Cargado";
      }
    },
    arrayFromFieldName(fname){
      return fname.split('.');
    },
    getNestedObject(nestedObj, pathArr) {
      return pathArr.reduce((obj, key) =>
        (obj && obj[key] !== 'undefined') ? obj[key] : undefined, nestedObj);
    }
  },
  mounted () {
    this.getInfo();
  },
  watch: {
    loading : function(newVal, oldVal){
      if(newVal == true){
         this.$vs.loading();
      }
      else {
        this.$vs.loading.close();
      }
    }
  }
}

</script>

<style lang="scss">
#avatar-col {
  width: 10rem;
}

#page-user-view {
  table {
    td {
        vertical-align: top;
        //min-width: 140px;
        padding-bottom: .8rem;
        //word-break: break-all;
        //max-width: 170px;
        overflow: hidden;
        text-overflow: ellipsis;
        //white-space: nowrap;
    }
    td:nth-child(even) {
      padding-left: 1rem !important;
    }

    &:not(.permissions-table) {
      td {
        @media screen and (max-width:370px) {
          display: block;
        }
      }
    }
  }
}


@media screen and (min-width:1201px) and (max-width:1211px),
only screen and (min-width:636px) and (max-width:991px) {
  #account-info-col-1 {
    width: calc(100% - 12rem) !important;
  }
}

</style>
